































import Vue from 'vue';
import AppMain from '@/components/AppMain.vue';
import AppListExpense from '@/components/AppListExpense.vue';
import AppListItem from '@/components/AppListItem.vue';
import AppListItemPhotos from '@/components/AppListItemPhotos.vue';
import EXPENSE from '@/graphql/queries/Expense.graphql';
import EXPENSE_DELETE from '@/graphql/mutations/ExpenseDelete.graphql';
import { CODES, EXPENSE_STATUSES } from '@/utils/constants';
import {
  Expense,
  ExpenseDeleteMutationVariables,
  ExpenseQueryVariables,
} from '@/types/schema';

export default Vue.extend({
  name: 'DriverExpensesDetails',
  components: {
    AppMain,
    AppListExpense,
    AppListItem,
    AppListItemPhotos,
  },
  data() {
    return {
      expense: {} as Expense,
    };
  },
  computed: {
    isPending(): boolean {
      return this.expense.status === EXPENSE_STATUSES.PENDING;
    },
  },
  methods: {
    async deleteExpense() {
      if (!confirm("Are you sure? This action can't be undone.")) return;

      await this.$apollo.mutate({
        mutation: EXPENSE_DELETE,
        variables: {
          input: {
            id: this.expense.id,
          },
        } as ExpenseDeleteMutationVariables,
      });
      this.$notify({
        text: 'Expense deleted!',
        type: 'success',
        duration: 6000,
      });
      this.$router.replace(
        this.$route.meta.backTo || { name: 'driver-expenses' }
      );
    },
  },
  apollo: {
    expense: {
      query: EXPENSE,
      variables(): ExpenseQueryVariables {
        return {
          id: this.$route.params.id,
        };
      },
      error({ graphQLErrors }) {
        const [gqlError] = graphQLErrors;
        if (gqlError?.extensions?.code === CODES.NOT_FOUND) {
          this.$router.replace({ name: 'driver-expenses' });
        }
      },
      fetchPolicy: 'cache-and-network',
    },
  },
});
